import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Flex, Table, Tag, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchCustomerQuoteList,
  setActiveQuote,
} from "../store/reducers/authReducer";
import AddQuoteForm from "./AddQuoteForm";
import LoadingSpinner from "./Loader";

const { Text } = Typography;

// Component for displaying Quotes table
const QuotesTable = ({ customer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeCustomerQuotes = useSelector(
    (state) => state?.auth?.customerQuotes
  );
  const [uploading, setUploading] = useState(false);
  const agentAuthToken = useSelector((state) => state?.auth?.token);

  const columns = [
    {
      title: "Quote ID",
      dataIndex: "quoteNumber",
      key: "quoteNumber",
      render: (_, record) => <>{record?.quoteNumber}</>,
    },
    {
      title: "Quote Type",
      dataIndex: "productType",
      key: "productType",
      render: (_, record) => <>{record?.productType}</>,
    },
    {
      title: "No Of Insured",
      dataIndex: "NumberOfInsured",
      key: "NumberOfInsured",
      render: (_, record) => <>{record?.NumberOfInsured}</>,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => {
        return <>{dayjs(record?.createdAt).format("DD-MMMM-YYYY")}</>;
      },
    },
    {
      title: "BQP",
      dataIndex: "agentBQP",
      key: "agentBQP",
      render: (_, record) => <>{record?.agentBQP}</>,
    },
    {
      title: "Status",
      dataIndex: "quoteStatus",
      key: "quoteStatus",
      render: (_, record) => (
        <>
          <Tag
            icon={
              record?.quoteStatus === "Accepted" ? (
                <CheckCircleOutlined />
              ) : record?.quoteStatus === "Rejected" ? (
                <CloseCircleOutlined />
              ) : (
                <SyncOutlined />
              )
            }
            color={
              record?.quoteStatus === "Accepted"
                ? "success"
                : record?.quoteStatus === "Rejected"
                ? "error"
                : ""
            }
          >
            {record?.quoteStatus}
          </Tag>
        </>
      ),
    },
  ];

  const [isQuoteFormVisisble, setQuoteFormVisible] = useState(false);

  const onViewDetailsForQuote = (data) => {
    dispatch(setActiveQuote(data));
    navigate({
      pathname: "/customer-account/new-quote",
    });
  };

  useEffect(() => {
    setUploading(true);
    const data = { customerId: customer?.id, token: agentAuthToken };
    dispatch(fetchCustomerQuoteList(data)).then((response) => {
      if (response?.payload?.data?.success === true) {
        setUploading(false);
      } else {
        setUploading(false);
      }
    });
  }, [agentAuthToken, customer, dispatch]);

  return (
    <>
      <LoadingSpinner loading={uploading} />

      <AddQuoteForm
        visible={isQuoteFormVisisble}
        setVisible={setQuoteFormVisible}
        customer={customer}
      />

      <Table
        title={() => {
          return (
            <Flex dir="row" justify={"space-between"}>
              <Text style={{ fontWeight: 600, fontSize: 16 }}>Quotes</Text>
              <Button
                type="primary"
                onClick={() => {
                  setQuoteFormVisible(true);
                }}
              >
                New Quote
              </Button>
            </Flex>
          );
        }}
        columns={columns}
        dataSource={activeCustomerQuotes}
        showSorterTooltip={true}
        pagination={false}
        scroll={{
          y: 376,
        }}
        rowKey="data"
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              onViewDetailsForQuote(record);
            },
          };
        }}
      />
    </>
  );
};

export default QuotesTable;
