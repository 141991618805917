import React, { useState } from "react";
import {
  Modal,
  Button,
  Upload,
  message,
  Form,
  Select,
  DatePicker,
  Input,
} from "antd";
import LoadingSpinner from "./Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  addPolicyData,
  fetchPolicyList,
} from "../store/reducers/customerReducer";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import UploadFile from "./UploadFile";

dayjs.extend(customParseFormat);
const dateFormat = "DD-MMMM-YYYY";
const threeYearAgo = dayjs().subtract(3, "year").format(dateFormat);
const threeYearAfter = dayjs().add(3, "year").format(dateFormat);

const AddExistingPolicyForm = ({ visible, setVisible, customer }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [policyStartDate, setPolicyStartDate] = useState("");
  const [policyEndDate, setPolicyEndDate] = useState("");
  const [savedFileName, setSavedFileName] = useState("");
  const activeAccount = useSelector((state) => state?.auth?.activeAccount);
  const agentAuthInfo = useSelector((state) => state?.auth);

  const insuranceProductData = [
    "Health Insurance",
    "Motor Insurance",
    "Corporate Insurance",
    "Other Insurance",
  ];

  const handleOk = async () => {
    try {
      if (savedFileName === "") {
        messageApi.error("Please upload a file.");
        return;
      }
      setLoading(true);
      const formData = await form.validateFields();
      formData["accountId"] = activeAccount?.id;
      formData["customerId"] = activeAccount?.customerId;
      formData["policyStartDate"] = policyStartDate;
      formData["policyEndDate"] = policyEndDate;
      formData["user_id"] = agentAuthInfo?.auth_profile?.user_id;
      formData["token"] = agentAuthInfo?.token;
      formData["policyFilename"] = savedFileName;

      dispatch(addPolicyData(formData)).then((result) => {
        if (result?.payload?.data?.success) {
          dispatch(fetchPolicyList(formData)).then((resp) => {
            messageApi.success("Policy Details added successfully.");
            setSavedFileName("");
            setLoading(false);
            setVisible(false);
            form.resetFields();
          });
        } else {
          messageApi.warning("something went wrong.");
          setLoading(false);
          setVisible(false);
        }
      });
    } catch (error) {
      messageApi.error("Failed to save policy information.");
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const beforeUpload = (file) => {
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error("File must be smaller than 10MB!");
    }
    return isLt10M ? false : Upload.LIST_IGNORE;
  };

  return (
    <>
      {contextHolder}
      <LoadingSpinner loading={loading} />

      <Modal
        title="Add Policy"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            disabled={savedFileName === "" ? true : false}
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Save
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="policyType"
            label="Policy Type"
            rules={[
              {
                required: true,
                message: "Please select Policy Type",
              },
            ]}
          >
            <Select
              placeholder="Please select Policy Type"
              options={insuranceProductData?.map((insuranceProduct) => ({
                label: insuranceProduct,
                value: insuranceProduct,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="policyNumber"
            label="Policy Number"
            rules={[
              { required: true, message: "Please enter Policy Number" },
              {
                pattern: /^[0-9A-Za-z\s.-]*$/,
                message:
                  "Please enter a valid Account Name (no special characters)",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Policy Start Date"
            name="policyStartDate"
            rules={[
              {
                required: true,
                message: "Please select Policy Start Date",
              },
            ]}
          >
            <DatePicker
              onChange={(date, dateString) => {
                setPolicyStartDate(dateString);
              }}
              style={{
                width: "100%",
              }}
              format={dateFormat}
              minDate={dayjs(threeYearAgo, dateFormat)}
            />
          </Form.Item>

          <Form.Item
            label="Policy End Date"
            name="policyEndDate"
            rules={[
              {
                required: true,
                message: "Please select Policy End Date",
              },
            ]}
          >
            <DatePicker
              onChange={(date, dateString) => {
                setPolicyEndDate(dateString);
              }}
              style={{
                width: "100%",
              }}
              format={dateFormat}
              minDate={dayjs(policyStartDate || threeYearAgo, dateFormat)}
              maxDate={dayjs(threeYearAfter, dateFormat)}
            />
          </Form.Item>
        </Form>
        <UploadFile
          folderPath={`${customer?.firstName}_${customer?.lastName}/${activeAccount?.name}/policies`}
          getItem={(item) => setSavedFileName(item)}
        />
      </Modal>
    </>
  );
};

export default AddExistingPolicyForm;
