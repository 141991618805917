import { db } from "../config/firebaseConfig";
import {
  collection,
  addDoc,
  query,
  getDocs,
  doc,
  getDoc,
  where,
  limit,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import API from "../store/utils/apiEndPoint";

const KOKORO_CUSTOMERS = "/kokoro-customers";

// Save Customer Health Form
export const addCustomerDataAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.put(
      `/customer-accounts/${payload?.declarationToken}/declaration/${payload?.quoteId}`,
      {
        addedByUserId: payload?.userId,
        groupId: payload?.groupId,
        isFinalSubmission: payload?.isFinalSubmission,
        declarationDetails: payload?.updatedCustomerDetails,
        declarationId: payload?.declarationId,
      },
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// Fetch Health Declaration Details
export const fetchCustomerDataAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.get(
      `/customer-accounts/${payload?.declarationToken}/declaration/${payload?.quoteId}`,
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// Fetch Customer profile
export const fetchCustomerProfileAPIHelper = async (data) => {
  try {
    const customerDocRef = doc(db, KOKORO_CUSTOMERS, data.customerId);
    const docSnap = await getDoc(customerDocRef);
    if (docSnap.exists()) {
      return {
        success: true,
        message: "Details saved Successfully",
        customerProfile: docSnap.data(),
      };
    } else {
      return { success: false, message: "No such Document" };
    }
  } catch (error) {
    throw error;
  }
};

// add Policy
export const addPolicyDataAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.post(
      `/customer-accounts/policies/${payload?.accountId}`,
      {
        addedByUserId: payload?.user_id,
        customerId: payload?.customerId,
        policyNumber: payload?.policyNumber,
        policyType: payload?.policyType,
        policyStartDate: payload?.policyStartDate,
        policyEndDate: payload?.policyEndDate,
        policyFilename: payload?.policyFilename,
      },
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// Fetch Policy
export const fetchPolicyListAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.get(
      `/customer-accounts/policies/${payload?.accountId}`,
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// To Delete Policy
export const deletePolicyAPIHelper = async (data) => {
  try {
    const customerDocRef = doc(
      db,
      KOKORO_CUSTOMERS,
      data?.customerId,
      "Policies",
      data?.policyId
    );
    const docSnap = await getDoc(customerDocRef);
    if (docSnap.exists()) {
      await updateDoc(customerDocRef, {
        isDeleted: true,
        lastModifiedDate: new Date(),
      });
      return { success: true, message: "Policy updated Successfully" };
    } else {
      return { success: false, message: "Policy not Found" };
    }
  } catch (error) {
    throw error;
  }
};

// add member
export const addMemberDataAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.post(
      `/customer-accounts/members/${payload?.accountId}`,
      {
        addedByUserId: payload?.user_id,
        customerId: payload?.customerId,
        memberFirstName: payload?.memberFirstName,
        memberLastName: payload?.memberLastName,
        memberRelation: payload?.memberRelation,
        memberDOB: payload?.memberDOB,
        memberPhoneNumber: payload?.memberPhoneNumber.toString(),
      },
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// fetch member
export const fetchMemberListAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.get(
      `/customer-accounts/members/${payload?.accountId}`,
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// To Delete Member
export const deleteMemberDataAPIHelper = async (data) => {
  try {
    const customerDocRef = doc(
      db,
      KOKORO_CUSTOMERS,
      data?.customerId,
      "Members",
      data?.memberId
    );
    const docSnap = await getDoc(customerDocRef);
    if (docSnap.exists()) {
      await updateDoc(customerDocRef, {
        isDeleted: true,
        lastModifiedDate: new Date(),
      });
      return { success: true, message: "Document updated Successfully" };
    } else {
      return { success: false, message: "Document not Found" };
    }
  } catch (error) {
    throw error;
  }
};

// add Document
export const addDocumentDataAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.post(
      `/customer-accounts/documents/${payload?.accountId}`,
      {
        addedByUserId: payload?.user_id,
        customerId: payload?.customerId,
        documentName: payload?.documentName,
        documentType: payload?.documentType,
        documentFilename: payload?.documentFilename,
      },
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// fetch Document
export const fetchDocsListAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.get(
      `/customer-accounts/documents/${payload?.accountId}`,
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// To Delete Document
export const deleteDocumentAPIHelper = async (data) => {
  try {
    const customerDocRef = doc(
      db,
      KOKORO_CUSTOMERS,
      data?.customerId,
      "Documents",
      data?.documentId
    );
    const docSnap = await getDoc(customerDocRef);
    if (docSnap.exists()) {
      await updateDoc(customerDocRef, {
        isDeleted: true,
        lastModifiedDate: new Date(),
      });
      return { success: true, message: "Document updated Successfully" };
    } else {
      return { success: false, message: "Document not Found" };
    }
  } catch (error) {
    throw error;
  }
};

// Save Customer Account Form
export const addCustomerAccountAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.post(
      `/customer-accounts/accounts`,
      {
        addedByUserId: payload?.user_id,
        groupId: payload?.groupId,
        customerId: payload?.customerId,
        accountName: payload?.accountName,
        accountDescription: payload?.description,
      },
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};

// Fetch Customer Accounts Details
export const fetchCustomerAccountAPIHelper = async (payload) => {
  try {
    const config = {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload?.token}`,
      },
    };
    let response = await API.get(
      `/customer-accounts/account-details/${payload?.customerId}`,
      config
    );
    return response;
  } catch (error) {
    return error?.response;
  }
};
