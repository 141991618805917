import React from "react";
import { Layout, Row, Col } from "antd";
import Login from "../components/Login";

function LoginPage() {
  const { Content } = Layout;
  return (
    <Layout style={{ minHeight: "75vh" }}>
      <Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row justify="center">
          <Col xs={24} sm={8} lg={6}>
            <Login />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default LoginPage;
