import React from "react";
import { Card, Flex } from "antd";
import KokoroLogo from "../data/images/KokoroLogo.png";
import { useSelector } from "react-redux";

function AppHeader() {
  const agentInfo = useSelector((state) => state?.auth?.auth_profile);

  return (
    <Card
      style={{
        width: "100%",
        border: "none",
        background: "#001529",
        borderRadius: "0px",
      }}
      styles={{
        body: { padding: 0, overflow: "hidden" },
      }}
    >
      {/* TODO: make header more neater , use only logo that is just black. We do not need to have big text */}
      <Flex
        justify="space-between"
        alignItems="center"
        style={{ paddingTop: 20, paddingBottom: 10 }}
      >
        <img
          width={"100px"}
          style={{ marginLeft: 30 }}
          alt="KOKORO INSURANCE BROKERS PRIVATE LIMITED"
          src={KokoroLogo}
        />
        <Flex style={{ paddingInlineEnd: 22 }}>
          <div>
            <h4 style={{ margin: 0, color: "white" }}>
              {agentInfo?.firstName} {agentInfo?.lastName}
            </h4>
            <p style={{ margin: 0, fontSize: 12, color: "white" }}>
              {agentInfo?.email}
            </p>
          </div>
        </Flex>
      </Flex>
    </Card>
  );
}

export default AppHeader;
