import React from "react";
import { Typography, Card } from "antd";
const { Text } = Typography;

function AppFooter() {
  return (
    <Card
      style={{
        position: "relative",
        zIndex: 999,
        bottom: 0,
        width: "100%",
        border: "none",
        background: "#001529",
        borderRadius: "0px",
      }}
      styles={{ body: { padding: 2, overflow: "hidden" } }}
    >
      <Typography style={{ textAlign: "center", margin: "2vh" }}>
        <Text
          style={{
            color: "white",
          }}
        >
          IRDAI Direct Broker ( Life & General) License No. 911 valid up to
          30-Aug-2026 CIN : U66220KA2023PTC174252. Insurance is a subject matter
          of solicitation.
        </Text>
        <br />
        <Text style={{ width: "100%", color: "white" }}>
          Copyright ©2024 By KOKORO INSURANCE BROKERS PRIVATE LIMITED
        </Text>
      </Typography>
    </Card>
  );
}

export default AppFooter;
