import React, { useState } from "react";
import { Card, Flex } from "antd";
import { message } from "antd";
import { Typography, Button } from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  CopyOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";

import AddCustomerForm from "../components/AddCustomerForm";

const { Title, Text } = Typography;

const ContactCard = ({ customer }) => {
  const [isCustomerFormVisisble, setCustomerFormVisible] = useState(false);

  const handleCopyEmail = (email) => {
    navigator.clipboard.writeText(email);
    message.success("Email copied to clipboard");
  };

  return (
    <div>
      <Title
        level={3}
        style={{ marginTop: 0,  width: "100% !important" }}
      >
        <Flex align={"center"} justify="space-between">
          <Flex vertical>
            {customer?.firstName} {customer?.lastName}
            <Flex vertical style={{padding: 8}}>

              <Flex style={{ fontSize: 16, marginBlock:8 }} >
                <MailOutlined /> <Text style={{marginLeft:8}}> {customer?.email}</Text>
              </Flex>
              <Flex style={{ fontSize: 16 }} >
                <PhoneOutlined /> <Text style={{marginLeft:8}}> {customer?.phone}</Text>
              </Flex>
              <Flex style={{ fontSize: 16, display: customer?.location ? 'flex': 'none',  }} >
                <EnvironmentOutlined /> <Text style={{marginLeft:8}}> {customer?.location}</Text>
              </Flex>

              {/* <Text style={{ fontSize: 16 }}>
                
                 {customer?.email}
                <a
                  style={{ paddingInline: 8 }}
                  href={"mailto:" + customer?.email}
                  onClick={() => handleCopyEmail(customer?.email)}
                >
                  {customer?.email} <CopyOutlined style={{ marginLeft: 5 }} />
                </a>
              </Text>
               */}
            </Flex>
          </Flex>
          <Flex vertical align="flex-end">
            <Button
              type="primary"
              onClick={() => {
                setCustomerFormVisible(true);
              }}
            >
              Modify 
            </Button>
          </Flex>
        </Flex>
      </Title>
      {/* Edit Customer Data */}
      <AddCustomerForm
        visible={isCustomerFormVisisble}
        setVisible={setCustomerFormVisible}
        initialValues={customer}
      />
    </div>
  );
};

// Component for displaying customer information
const CustomerInformation = ({ customer }) => (
  <Card title="Customer Account" style={{ marginBottom: "20px" }}>
    <Flex justify="space-between">
      <Flex vertical style={{ width: "100%" }}>
        <ContactCard customer={customer} />
      </Flex>
    </Flex>
  </Card>
);

export default CustomerInformation;
