import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, InputNumber } from "antd";
import { addCustomer, deleteCustomer, updateCustomer } from "../helpers/authAPI";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { fetchCustomersList, setActiveCustomer } from "../store/reducers/authReducer";
import LoadingSpinner from "./Loader";
import { useNavigate } from "react-router-dom";

const AddCustomerForm = ({ visible, setVisible, initialValues = {} }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const agentAuthInfo = useSelector((state) => state?.auth);
  const navigate = useNavigate()

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(initialValues); // Pre-fill form in edit mode

      console.warn("customer", initialValues)
    }
  }, [visible, initialValues, form]);

  const handleOk = async () => {
    try {
      setLoading(true);
      const formData = await form.validateFields();

      formData["user_id"] = agentAuthInfo?.auth_profile?.user_id;
      formData["token"] = agentAuthInfo?.token;
      

      // selected a customer, update process
      if (initialValues?.id) {
        formData["id"] = initialValues?.id;
        updateCustomer(formData)
        .then((response) => {
          if (response.data.success) {
            messageApi.open({
              type: "success",
              content: response?.data?.message,
              duration: 5,
            });
            // dispatch(fetchCustomersList(formData));
            dispatch(setActiveCustomer(response.data.customer))
          } else {
            messageApi.open({
              type: "error",
              content: response?.data?.message,
              duration: 5,
            });
          }
          setLoading(false);
          setVisible(false);
          form.resetFields();
        })
        .catch((err) => {
          messageApi.open({
            type: "error",
            content: "Unable to add customer. Please try again",
            duration: 5,
          });
          setLoading(false);
          setVisible(false);
        });

      } else {
        addCustomer(formData)
          .then((response) => {
            if (response.data.success) {
              messageApi.open({
                type: "success",
                content: response?.data?.message,
                duration: 5,
              });
              dispatch(fetchCustomersList(formData));
            } else {
              messageApi.open({
                type: "error",
                content: response?.data?.message,
                duration: 5,
              });
            }
            setLoading(false);
            setVisible(false);
            form.resetFields();
          })
          .catch((err) => {
            messageApi.open({
              type: "error",
              content: "Unable to add customer. Please try again",
              duration: 5,
            });
            setLoading(false);
            setVisible(false);
          });
      }
    } catch (error) {
      console.error("Error saving customer information:", error);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleDeleteCustomer = async () => {
    //TODO: drop customer
    try {
      setLoading(true);
      const formData = await form.validateFields();

      formData["user_id"] = agentAuthInfo?.auth_profile?.user_id;
      formData["token"] = agentAuthInfo?.token;
    
      // selected a customer, update process
      if (initialValues?.id) {
        formData["id"] = initialValues?.id;

        deleteCustomer(formData)
        .then((response) => {
          if (response.data.success) {
            messageApi.open({
              type: "success",
              content: response?.data?.message,
              duration: 5,
            });
            // dispatch(fetchCustomersList(formData));
            dispatch(setActiveCustomer(null))
            navigate("/");

          } else {
            messageApi.open({
              type: "error",
              content: response?.data?.message,
              duration: 5,
            });
          }
          setLoading(false);
          setVisible(false);
          form.resetFields();
        })
        .catch((err) => {
          messageApi.open({
            type: "error",
            content: "Unable to add customer. Please try again",
            duration: 5,
          });
          setLoading(false);
          setVisible(false);
        });

      }
    } catch (error) {
      console.error("Error saving customer information:", error);
      setLoading(false);
    }

  }

  return (
    <>
      {contextHolder}
      <LoadingSpinner loading={loading} />

      <Modal
        title="Customer Information"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key={"delete"} onClick={handleDeleteCustomer} type="primary" danger ghost style={{float: 'left'}}>
            Delete
          </Button>,
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Save
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" initialValues={initialValues}>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[
              { required: true, message: "Please enter your first name" },
              {
                pattern: /^[A-Za-z\s.-]*$/,
                message:
                  "Please enter a valid First Name (no special characters)",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[
              { required: true, message: "Please enter your last name" },
              {
                pattern: /^[A-Za-z\s.-]*$/,
                message:
                  "Please enter a valid Last Name (no special characters)",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[
              { required: true, message: "Please enter your phone number" },
              {
                pattern: /^[0-9]{10}$/,
                message: "Phone Number should contain only 10 digits",
              },
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              minLength={10}
              maxLength={10}
            />
          </Form.Item>
          <Form.Item
            label="Location"
            name="location"
            rules={[
              {
                required: false,
                message: "Please enter Location",
              },
            ]}
          >
            <Input type="text" placeholder="Enter Location" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddCustomerForm;
