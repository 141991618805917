import React, { useState } from "react";
import { Modal, Form, Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { addCustomerAccountAPIHelper } from "../helpers/customerAPI";
import { fetchCustomerAccountsList } from "../store/reducers/customerReducer";

const AddAccountForm = ({ visible, setVisible, customer }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const agentAuthInfo = useSelector((state) => state?.auth);
  const groupId = useSelector((state) => {
    const groupsList = state?.team?.groupsList;
    const staffGroup = groupsList?.find((group) => group?.name === "customers");
    return staffGroup?.id;
  });

  const handleOk = async (values) => {
    try {
      setLoading(true);
      const formData = await form.validateFields();
      formData["user_id"] = agentAuthInfo?.auth_profile?.user_id;
      formData["token"] = agentAuthInfo?.token;
      formData["groupId"] = groupId;
      formData["customerId"] = customer?.id;

      addCustomerAccountAPIHelper(formData)
        .then((response) => {
          if (response?.data?.success) {
            messageApi.open({
              type: "success",
              content: response?.data?.message,
              duration: 5,
            });
            form.resetFields();
            dispatch(fetchCustomerAccountsList(formData));
          } else {
            messageApi.open({
              type: "error",
              content: response?.data?.message,
              duration: 5,
            });
          }
          setLoading(false);
          setVisible(false);
        })
        .catch((err) => {
          messageApi.open({
            type: "error",
            content: "Unable to add customer. Please try again",
            duration: 5,
          });
          setLoading(false);
          setVisible(false);
        });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error saving customer information:",
        duration: 5,
      });
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      {contextHolder}

      <Modal
        title="Account Information"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Save
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="accountName"
            label="Account Name"
            rules={[
              { required: true, message: "Please enter your Account name" },
              {
                pattern: /^[A-Za-z\s.-]*$/,
                message:
                  "Please enter a valid Account Name (no special characters)",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddAccountForm;
