import React from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import API from "../store/utils/apiEndPoint";

const UploadFile = ({ folderPath, getItem }) => {
  const uploadDocument = async (file) => {
    try {
      const response = await API.get(
        `/generate-presigned-url?filename=${file?.name}&contentType=${file?.type}&folderPath=${folderPath}`
      );
      const url = response?.data?.url;
      const savedFilename = response?.data?.savedFilename;
      getItem(savedFilename);

      const uploadResponse = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": file?.type,
        },
        body: file,
      });

      if (uploadResponse?.ok) {
        message.success("File uploaded successfully!");
      } else {
        message.error("File upload failed.");
      }
    } catch (error) {
      console.error("Error during file upload:", error);
      message.error("File upload failed.");
    }
  };

  const handleUpload = async (info) => {
    const file = info?.file;
    const isPdfOrImage =
      file.type === "application/pdf" ||
      file.type === "image/jpeg" ||
      file.type === "image/png";

    if (!isPdfOrImage) {
      message.error("You can only upload PDF or image files!");
      return;
    }
    await uploadDocument(file);
  };

  return (
    <div>
      <Upload
        customRequest={handleUpload}
        accept=".pdf,.jpg,.jpeg,.png"
        showUploadList={false}
      >
        <p className="ant-upload-text">Please Upload your file</p>
        <Button icon={<UploadOutlined />}>Upload Document</Button>
      </Upload>
    </div>
  );
};

export default UploadFile;
