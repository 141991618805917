import React, { useState } from "react";
import { Modal, Button, Upload, message, Form, Select, Input } from "antd";
import LoadingSpinner from "./Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  addDocumentData,
  fetchDocsList,
} from "../store/reducers/customerReducer";
import UploadFile from "./UploadFile";

const AddCustomerDocsForm = ({ visible, setVisible, customer }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [savedFileName, setSavedFileName] = useState("");
  const activeAccount = useSelector((state) => state?.auth?.activeAccount);
  const agentAuthInfo = useSelector((state) => state?.auth);

  const documentTypeData = [
    "Address Proof",
    "Identity Proof",
    "Income Proof",
    "Medical Records",
    "Asset Information Document",
    "Other Documents",
  ];

  const handleOk = async () => {
    try {
      if (savedFileName === "") {
        messageApi.error("Please upload a file.");
        return;
      }
      setLoading(true);
      const firebaseFormData = await form.validateFields();
      firebaseFormData["accountId"] = activeAccount?.id;
      firebaseFormData["customerId"] = activeAccount?.customerId;
      firebaseFormData["user_id"] = agentAuthInfo?.auth_profile?.user_id;
      firebaseFormData["token"] = agentAuthInfo?.token;
      firebaseFormData["documentFilename"] = savedFileName;

      dispatch(addDocumentData(firebaseFormData)).then((result) => {
        if (result?.payload?.data?.success) {
          dispatch(fetchDocsList(firebaseFormData)).then((resp) => {
            messageApi.success("File uploaded successfully.");
            setSavedFileName("");
            setLoading(false);
            setVisible(false);
            form.resetFields();
          });
        } else {
          messageApi.warning("something went wrong.");
          setLoading(false);
          setVisible(false);
        }
      });
    } catch (error) {
      messageApi.error("Failed to save document information.");
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const beforeUpload = (file) => {
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error("File must be smaller than 10MB!");
    }
    return isLt10M ? false : Upload.LIST_IGNORE;
  };

  return (
    <>
      {contextHolder}
      <LoadingSpinner loading={loading} />

      <Modal
        title="Add Document"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            disabled={savedFileName === "" ? true : false}
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Save
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="documentType"
            label="Document Type"
            rules={[
              {
                required: true,
                message: "Please select Document Type",
              },
            ]}
          >
            <Select
              placeholder="Please select Document Type"
              options={documentTypeData?.map((docType) => ({
                label: docType,
                value: docType,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="documentName"
            label="Document Name"
            rules={[
              { required: true, message: "Please enter Document Name" },
              {
                pattern: /^[A-Za-z\s.-]*$/,
                message:
                  "Please enter a valid Document Name (no special characters)",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>

        <UploadFile
          folderPath={`${customer?.firstName}_${customer?.lastName}/${activeAccount?.name}/documents`}
          getItem={(item) => setSavedFileName(item)}
        />
      </Modal>
    </>
  );
};

export default AddCustomerDocsForm;
