import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  message,
} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { logInUser, onBoardingUser } from "../store/reducers/authReducer";
import LoadingSpinner from "../components/Loader";
import { useNavigate } from "react-router-dom";

function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checksignedIn, setChecksignedIn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [passwordValidated, setPasswordValidated] = useState(true);

  const handleSigninType = () => {
    setChecksignedIn(false);
  };
  const handleLoginType = () => {
    setChecksignedIn(true);
  };

  const onFinish = (values) => {
    setLoading(true);
    if (checksignedIn === false) {
      const data = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        password: values?.password,
      };
      dispatch(onBoardingUser(data))
        .then((response) => {
          if (response?.payload?.data?.success) {
            message.success(response?.payload?.data?.message);
            setLoading(false);
            if (response?.payload?.data?.user?.isApproved === true) {
              navigate({ pathname: "/dashboard" });
            }
          } else {
            message.warning(response?.payload?.data?.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          message.error("Something went Wrong, Please try again.");
          setLoading(false);
        });
    } else {
      const data = {
        email: values?.email,
        password: values?.password,
      };
      dispatch(logInUser(data))
        .then((response) => {
          if (response?.payload?.data?.success) {
            message.success(response?.payload?.data?.message);
            setLoading(false);
            navigate({ pathname: "/dashboard" });
          } else {
            message.warning(response?.payload?.data?.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          message.error("Something went Wrong, Please try again.");
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };

  const formItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
    },
  };

  return (
    <>
      <Row xs={24} sm={12} lg={12}>
        <LoadingSpinner loading={loading} />

        <Card
          size={"default"}
          color={"green"}
          style={{
            width: "100vw",
            height: "70vh",
            marginTop: "2%",
            marginBottom: "2%",
          }}
        >
          <Divider style={{ fontSize: "12px" }}>
            {checksignedIn ? "LOGIN VIA EMAIL" : "SIGN UP VIA EMAIL"}
          </Divider>

          <Row lg={24}>
            <Col span={24}>
              <Form
                {...formItemLayout}
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true, prefix: "91" }}
                onSubmit={onSubmit}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                {checksignedIn === false ? (
                  <>
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Enter valid First Name!",
                        },
                      ]}
                    >
                      <Input
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                        placeholder="firstName"
                      />
                    </Form.Item>
                    <Form.Item
                      name="lastName"
                      rules={[
                        { required: true, message: "Enter valid last Name!" },
                      ]}
                    >
                      <Input
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                        placeholder="lastName"
                      />
                    </Form.Item>
                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: "Enter valid Email!" },
                      ]}
                    >
                      <Input
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                        placeholder="Email"
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        { required: true, message: "Enter valid Password!" },
                      ]}
                    >
                      <Input
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder="Password"
                      />
                    </Form.Item>
                    <Form.Item
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: "Enter Confirm Password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              setPasswordValidated(true);
                              return Promise.resolve();
                            }
                            setPasswordValidated(false);
                            return Promise.reject(
                              new Error(
                                "The new password that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder="Confirm Password"
                      />
                    </Form.Item>

                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      disabled={!passwordValidated}
                      block
                    >
                      Sign Up
                    </Button>
                  </>
                ) : (
                  <>
                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: "Enter valid Email!" },
                      ]}
                    >
                      <Input
                        prefix={
                          <UserOutlined className="site-form-item-icon" />
                        }
                        placeholder="Email"
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        { required: true, message: "Enter valid Password!" },
                      ]}
                    >
                      <Input
                        prefix={
                          <LockOutlined className="site-form-item-icon" />
                        }
                        type="password"
                        placeholder="Password"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        block
                      >
                        Log in
                      </Button>
                    </Form.Item>
                  </>
                )}

                <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>
                </Form.Item>
                <p id="Errormsg" style={{ color: "red" }}></p>
              </Form>
            </Col>
          </Row>

          <Row style={{ padding: 4, marginTop: 4 }}>
            {checksignedIn === false ? (
              <Row style={{ padding: 4, marginTop: 4 }}>
                <p>
                  Already have an Account?{" "}
                  <a onClick={handleLoginType}> Login </a>
                </p>
              </Row>
            ) : (
              <Row style={{ padding: 4, marginTop: 4 }}>
                <p>
                  New here?
                  <a onClick={handleSigninType}> Create Account </a>
                </p>
              </Row>
            )}
          </Row>
        </Card>
      </Row>
    </>
  );
}

export default Signup;
