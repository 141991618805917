import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Card,
  DatePicker,
  Divider,
  Select,
  message,
  InputNumber,
  Flex,
  Typography,
  FloatButton,
  Modal,
  Alert,
  Space,
  Tooltip,
} from "antd";
import {
  CopyOutlined,
  FormOutlined,
  HeartOutlined,
  SettingOutlined,
  ShareAltOutlined,
  DeleteTwoTone,
} from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  fetchCustomerData,
  saveCustomerData,
} from "../store/reducers/customerReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../components/Loader";
import TextArea from "antd/es/input/TextArea";
import "../App.css"; // Import CSS file for styles
import {
  EmailIcon,
  EmailShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Marquee from "react-fast-marquee";

const { Title } = Typography;
dayjs.extend(customParseFormat);
const dateFormat = "DD-MMMM-YYYY";
const tenYearAgo = dayjs().subtract(10, "year").format(dateFormat);
const oneDayAgo = dayjs().subtract(2, "day").format(dateFormat);

const BlinkingWarningIcon = () => {
  return (
    <div className="blinking-icon">
      <HeartOutlined type="warning" style={{ fontSize: 20, color: "red" }} />
    </div>
  );
};

const AgentViewHealthDeclarationForm = () => {
  const [form] = Form.useForm();
  const SELECT_PLACEHOLDER = "Select any one";
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const [uploading, setUploading] = useState(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState("");

  const navigate = useNavigate();
  const customerDetails = useSelector(
    (state) => state?.customer?.customerDetails
  );
  const activeCustomer = useSelector((state) => state?.auth?.activeCustomer);
  const activeQuote = useSelector((state) => state?.auth?.activeQuote);
  const agentAuthToken = useSelector((state) => state?.auth?.token);
  const agentAuthInfo = useSelector((state) => state?.auth?.auth_profile);

  const shareEmailBody = `Hello ${activeCustomer?.firstName} ${activeCustomer?.lastName},
  Please use the below link to complete your Health Declaration Form. Once you have completed the form, our team will get back to you.`;

  const onFinish = (values) => {
    setUploading(true);
    setIsDisabled(true);
    const data = {
      updatedCustomerDetails: values,
      quoteId: customerDetails?.quoteId,
      isFinalSubmission: customerDetails?.isFinalSubmission,
      groupId: activeCustomer?.groupId,
      userId: agentAuthInfo?.user_id,
      token: agentAuthToken,
      declarationToken: activeQuote?.declarationToken,
      declarationId: customerDetails?.id,
    };

    dispatch(saveCustomerData(data))
      .then((resp) => {
        if (resp?.payload?.data?.success === true) {
          setUploading(false);
          message.success(resp?.payload?.data?.message);
        } else {
          setUploading(false);
          message.warning("something went wrong!");
        }
      })
      .catch((error) => {
        message.warning("something went wrong!");
        setUploading(false);
      });
    setUploading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleShareOk = () => {
    setIsShareModalOpen(false);
  };

  const handleShareCancel = () => {
    setIsShareModalOpen(false);
  };

  useEffect(() => {
    setUploading(true);
    const data = {
      token: agentAuthToken,
      quoteId: activeQuote?.id,
      declarationToken: activeQuote?.declarationToken,
    };
    dispatch(fetchCustomerData(data)).then((response) => {
      if (response?.payload?.data?.success === true) {
        const newDataArray =
          response?.payload?.data?.customerDetails?.declarationValue?.items?.map(
            (item) => {
              var newItem = { ...item };
              newItem.dob = item.dob ? dayjs(item?.dob) : undefined;
              if (newItem.diabetesDuration) {
                newItem.diabetesDuration = dayjs(item.diabetesDuration);
              }
              if (newItem.bloodpressureDuration) {
                newItem.bloodpressureDuration = dayjs(
                  item.bloodpressureDuration
                );
              }
              if (newItem.asthmaDuration) {
                newItem.asthmaDuration = dayjs(item.asthmaDuration);
              }
              if (newItem.cholesterolDuration) {
                newItem.cholesterolDuration = dayjs(item.cholesterolDuration);
              }
              if (newItem.thyroidDuration) {
                newItem.thyroidDuration = dayjs(item.thyroidDuration);
              }
              return newItem;
            }
          );
        form.setFieldsValue(
          response?.payload?.data?.customerDetails?.declarationValue
        );
        form.setFieldsValue({
          items: newDataArray,
          proposerDOB: response?.payload?.data?.customerDetails
            ?.declarationValue?.proposerDOB
            ? dayjs(
                response?.payload?.data?.customerDetails?.declarationValue
                  ?.proposerDOB
              )
            : undefined,
          NomineeDOB: response?.payload?.data?.customerDetails?.declarationValue
            ?.NomineeDOB
            ? dayjs(
                response?.payload?.data?.customerDetails?.declarationValue
                  ?.NomineeDOB
              )
            : undefined,
        });
        if (form.getFieldValue("items") === undefined) {
          form.setFieldsValue({
            items: Array.from(
              {
                length:
                  response?.payload?.data?.customerDetails?.declarationValue
                    ?.NumberOfInsured,
              },
              () => ({})
            ),
          });
        }
        setUploading(false);
      } else {
        setUploading(false);
      }
    });
  }, [agentAuthToken, activeQuote?.id, form, dispatch, navigate]);

  return (
    <Row
      style={{ minHeight: "75vh", width: "100%" }}
      justify="center"
      align="middle"
    >
      <LoadingSpinner loading={uploading} />

      <Alert
        banner
        message={
          <Marquee pauseOnHover gradient={false}>
            This is a View only Page. Please select Edit from More Options to
            Edit the Health Decleration Form. Please select Share from More
            Options to Share the Health Decleration Form.
          </Marquee>
        }
      />

      <Modal
        title="Share Health Declaration"
        open={isShareModalOpen}
        onOk={handleShareOk}
        onCancel={handleShareCancel}
        centered
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <h3>
            Share a health declaration form with customer to get access to their
            health profile.
          </h3>

          <WhatsappShareButton
            url={shareUrl}
            title="Health Declaration Form"
            style={{ width: "50%" }}
          >
            <Button
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
              block={true}
            >
              <WhatsappIcon size={24} round={true} />
              Share on Whatsapp
            </Button>
          </WhatsappShareButton>

          <EmailShareButton
            url={shareUrl}
            subject="Health Declaration Form"
            body={shareEmailBody}
            style={{ width: "50%" }}
          >
            <Button
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
              }}
              block={true}
            >
              <EmailIcon size={24} round={true} />
              Share via Your Email
            </Button>
          </EmailShareButton>

          <Button
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              width: "50%",
            }}
            block={true}
            onClick={async () => {
              await navigator.clipboard.writeText(shareUrl);
              message.success("Health Declaration Link copied to clipboard");
            }}
          >
            <CopyOutlined size={24} />
            Copy to Clipboard
          </Button>
        </div>
      </Modal>

      <Flex
        vertical
        justify="center"
        align="center"
        style={{ padding: 8, width: "100%", backgroundColor: "#faad14c9" }}
      >
        <Flex
          style={{ flexDirection: "row" }}
          align="center"
          justify="flex-start"
        >
          <BlinkingWarningIcon />{" "}
          <Title level={3}> Health Declaration Form </Title>
        </Flex>
      </Flex>

      <Form
        layout="vertical"
        form={form}
        name="Health Declaration Form"
        style={{
          width: "100%",
          marginBottom: "5%",
        }}
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{}}
      >
        <Row
          gutter={16}
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <Col
            xs={24}
            sm={12}
            lg={8}
            style={{ width: "100%" }}
            justifyContent="center"
          >
            <Card
              key={"Client_Information_Sheet"}
              style={{ marginTop: "5%", backgroundColor: "#FAF5EB" }}
            >
              <Divider orientation="left" orientationMargin="0">
                Contact Information
              </Divider>

              <Form.Item
                label="Proposer Name"
                name="proposerName"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Proposer Name!",
                  },
                  {
                    pattern: /^[A-Za-z\s.-]*$/,
                    message:
                      "Please enter a valid Proposer Name (no special characters)",
                  },
                ]}
              >
                <Input disabled={isDisabled} />
              </Form.Item>

              <Form.Item
                label="Email ID"
                name="proposerEmail"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please enter your valid Email ID!",
                  },
                ]}
              >
                <Input disabled={isDisabled} />
              </Form.Item>

              <Form.Item
                label="Mobile Number"
                name="proposerPhone"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Mobile Number!",
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Mobile Number should contain only 10 digits",
                  },
                ]}
              >
                <InputNumber
                  disabled={isDisabled}
                  style={{ width: "100%" }}
                  minLength={10}
                  maxLength={10}
                />
              </Form.Item>

              <Form.Item
                label="Alternate Mobile Number"
                name="AlternateMobileNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Alternate Mobile Number!",
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Phone Number should contain only 10 digits",
                  },
                ]}
              >
                <InputNumber
                  disabled={isDisabled}
                  style={{ width: "100%" }}
                  minLength={10}
                  maxLength={10}
                />
              </Form.Item>

              <Form.Item
                label="Full Address"
                name="FullAddress"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Full Address!",
                  },
                ]}
              >
                <TextArea disabled={isDisabled} rows={1} />
              </Form.Item>

              <Divider orientation="left" orientationMargin="0">
                Insurance Needs
              </Divider>

              <Form.Item
                label="No of Insured"
                name="NumberOfInsured"
                rules={[
                  {
                    required: true,
                    message: "Please enter your No. of Insured!",
                  },
                ]}
              >
                <Input type="number" disabled={true} min={1} max={15} />
              </Form.Item>

              <Form.Item
                label="Is Proposer included"
                name="Proposerincluded"
                rules={[
                  {
                    required: true,
                    message: "Please select your Is Proposer included!",
                  },
                ]}
              >
                <Select
                  disabled={isDisabled}
                  placeholder={SELECT_PLACEHOLDER}
                  options={[
                    {
                      value: "Yes",
                      label: "Yes",
                    },
                    {
                      value: "No",
                      label: "No",
                    },
                  ]}
                />
              </Form.Item>
            </Card>
          </Col>

          <Col
            xs={24}
            sm={12}
            lg={8}
            style={{ width: "100%" }}
            justifyContent="center"
          >
            <Card
              key={"Client_Information_Sheet"}
              style={{ marginTop: "5%", backgroundColor: "#FAF5EB" }}
            >
              <Divider orientation="left" orientationMargin="0">
                Additional Information
              </Divider>

              <Form.Item
                label="Proposer Date of Birth"
                name="proposerDOB"
                rules={[
                  {
                    required: true,
                    message: "Please select Proposer Date of Birth!",
                  },
                ]}
              >
                <DatePicker
                  disabled={isDisabled}
                  style={{
                    width: "100%",
                  }}
                  format={dateFormat}
                  maxDate={dayjs(tenYearAgo, dateFormat)}
                />
              </Form.Item>

              <Form.Item
                label="Gender"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "Please select your Gender!",
                  },
                ]}
              >
                <Select
                  disabled={isDisabled}
                  placeholder={SELECT_PLACEHOLDER}
                  options={[
                    {
                      value: "Male",
                      label: "Male",
                    },
                    {
                      value: "Female",
                      label: "Female",
                    },
                    {
                      value: "Other",
                      label: "Other",
                    },
                  ]}
                />
              </Form.Item>

              <Form.Item
                label="Marital Status"
                name="maritalstatus"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Marital Status!",
                  },
                ]}
              >
                <Select
                  disabled={isDisabled}
                  placeholder={SELECT_PLACEHOLDER}
                  options={[
                    {
                      value: "Married",
                      label: "Married",
                    },
                    {
                      value: "Unmarried",
                      label: "Unmarried",
                    },
                    {
                      value: "Other",
                      label: "Other",
                    },
                  ]}
                />
              </Form.Item>
              <br />
              <br />

              <Divider orientation="left" orientationMargin="0">
                Occupation
              </Divider>

              <Form.Item
                label="Occupation"
                name="occupation"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Occupation!",
                  },
                  {
                    pattern: /^[A-Za-z\s.-]*$/,
                    message:
                      "Please enter a valid Occupation (no special characters)",
                  },
                ]}
              >
                <Input disabled={isDisabled} />
              </Form.Item>

              <Form.Item
                label="Company Name"
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: "Please enter your company name!",
                  },
                  {
                    pattern: /^[A-Za-z\s.-]*$/,
                    message:
                      "Please enter a valid Proposer Name (no special characters)",
                  },
                ]}
              >
                <Input disabled={isDisabled} />
              </Form.Item>

              <Form.Item
                label="Annual Income"
                name="annualIncome"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Annual Income!",
                  },
                ]}
              >
                <Select
                  disabled={isDisabled}
                  placeholder={SELECT_PLACEHOLDER}
                  options={[
                    {
                      value: "Upto 25,000",
                      label: "Upto 25,000",
                    },
                    {
                      value: "25,001 to 50,000",
                      label: "25,001 to 50,000",
                    },
                    {
                      value: "50,001 to 1,00,000",
                      label: "50,001 to 1,00,000",
                    },
                    {
                      value: "1,00,001 to 2,00,000",
                      label: "1,00,001 to 2,00,000",
                    },
                    {
                      value: "2,00,001 to 3,00,000",
                      label: "2,00,001 to 3,00,000",
                    },
                    {
                      value: "3,00,001 to 4,00,000",
                      label: "3,00,001 to 4,00,000",
                    },
                    {
                      value: "4,00,001 to 5,00,000",
                      label: "4,00,001 to 5,00,000",
                    },
                    {
                      value: "5,00,001 to 6,00,000",
                      label: "5,00,001 to 6,00,000",
                    },
                    {
                      value: "Above 6,00,000",
                      label: "Above 6,00,000",
                    },
                  ]}
                />
                {/* <Input /> */}
              </Form.Item>
              <br />
            </Card>
          </Col>

          <Col
            xs={24}
            sm={12}
            lg={8}
            style={{ width: "100%" }}
            justifyContent="center"
          >
            <Card
              key={"Client_Information_Sheet"}
              style={{ marginTop: "5%", backgroundColor: "#FAF5EB" }}
            >
              <Divider orientation="left" orientationMargin="0">
                KYC Information
              </Divider>

              <Form.Item
                label="PAN Number"
                name="PANnumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter your PAN Number!",
                  },
                  {
                    pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                    message:
                      "PAN Number should contain exactly 10 alphanumeric characters.",
                  },
                ]}
              >
                <Input disabled={isDisabled} maxLength={10} minLength={10} />
              </Form.Item>

              <Form.Item
                label="Aadhaar Number"
                name="AadhaarNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Aadhaar Number",
                  },
                  {
                    pattern: /^[0-9]{12}$/,
                    message: "Aadhaar Number should contain only 12 digits",
                  },
                ]}
              >
                <InputNumber
                  disabled={isDisabled}
                  style={{ width: "100%" }}
                  maxLength={12}
                  minLength={12}
                />
              </Form.Item>

              <Form.Item
                label="E-Insurance Account"
                name="EInsuranceAccount"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please enter your E-Insurance Account!",
                  // },
                  {
                    pattern: /^[A-Za-z0-9]*$/,
                    message:
                      "E-Insurance Account can contain only alphanumeric characters.",
                  },
                ]}
              >
                <Input disabled={isDisabled} />
              </Form.Item>

              <br />
              <br />
              <Divider orientation="left" orientationMargin="0">
                Nominee
              </Divider>

              <Form.Item
                label="Nominee Name"
                name="NomineeName"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Nominee Name!",
                  },
                  {
                    pattern: /^[A-Za-z\s.]*$/,
                    message:
                      "Please enter a valid Nominee Name (no special characters)",
                  },
                ]}
              >
                <Input disabled={isDisabled} />
              </Form.Item>

              <Form.Item
                label="Nominee Date of Birth"
                name="NomineeDOB"
                rules={[
                  {
                    required: true,
                    message: "Please select Nominee Date of Birth!",
                  },
                ]}
              >
                <DatePicker
                  disabled={isDisabled}
                  style={{
                    width: "100%",
                  }}
                  format={dateFormat}
                  maxDate={dayjs(oneDayAgo, dateFormat)}
                />
              </Form.Item>

              <Form.Item
                label="Nominee Relation"
                name="NomineeRelation"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Nominee Relation!",
                  },
                  {
                    pattern: /^[A-Za-z\s-]*$/,
                    message:
                      "Please enter a valid Nominee Relation (no special characters)",
                  },
                ]}
              >
                <Input disabled={isDisabled} />
              </Form.Item>
              <br />
            </Card>
          </Col>
        </Row>

        <Form.List name="items">
          {(fields, { add, remove }) => (
            <div
              style={{
                display: "flex",
                rowGap: 16,
                flexDirection: "column",
              }}
            >
              {fields.map((field) => (
                <>
                  <Card
                    title={`Insured Person ${field.name + 1}`}
                    style={{ marginTop: "5%", backgroundColor: "#FAF5EB" }}
                    extra={
                      <>
                        {!isDisabled && (
                          <Tooltip
                            title={
                              fields.length === 1
                                ? "Disabled"
                                : "Delete Insured Person"
                            }
                          >
                            <Button
                              disabled={
                                form.getFieldsValue().items.length === 1
                              }
                              style={{ justifySelf: "center", flex: "col" }}
                              onClick={() => {
                                const myNum = form.getFieldsValue()?.items;
                                myNum.splice(field.name, 1);
                                form.setFieldsValue({ items: myNum });
                                form.setFieldsValue({
                                  NumberOfInsured: myNum?.length,
                                });
                              }}
                            >
                              <DeleteTwoTone twoToneColor="#dc3545" />
                            </Button>
                          </Tooltip>
                        )}
                      </>
                    }
                  >
                    <Card
                      size="small"
                      key={field.key}
                      style={{ border: "none", backgroundColor: "#FAF5EB" }}
                    >
                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Full Name as per Govt. ID"
                            name={[field.name, "fullName"]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter your Full Name!",
                              },
                              {
                                pattern: /^[A-Za-z\s.-]*$/,
                                message:
                                  "Please enter a valid Full Name (no special characters)",
                              },
                            ]}
                          >
                            <Input disabled={isDisabled} />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Date of Birth"
                            name={[field.name, "dob"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select your Date of Birth!",
                              },
                            ]}
                          >
                            <DatePicker
                              disabled={isDisabled}
                              style={{
                                width: "100%",
                              }}
                              format={dateFormat}
                              maxDate={dayjs(oneDayAgo, dateFormat)}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Gender"
                            name={[field.name, "gender"]}
                            rules={[
                              {
                                required: true,
                                message: "Please select your Gender!",
                              },
                            ]}
                          >
                            <Select
                              disabled={isDisabled}
                              placeholder={SELECT_PLACEHOLDER}
                              options={[
                                {
                                  value: "Male",
                                  label: "Male",
                                },
                                {
                                  value: "Female",
                                  label: "Female",
                                },
                                {
                                  value: "Other",
                                  label: "Other",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Occupation"
                            name={[field.name, "occupation"]}
                            rules={[
                              // {
                              //   required: true,
                              //   message: "Please enter your Occupation!",
                              // },
                              {
                                pattern: /^[A-Za-z\s.-]*$/,
                                message:
                                  "Please enter a valid Occupation (no special characters)",
                              },
                            ]}
                          >
                            <Input disabled={isDisabled} />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Height (in Cm)"
                            name={[field.name, "height"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please enter your Height!",
                            //   },
                            // ]}
                          >
                            <InputNumber
                              disabled={isDisabled}
                              style={{ width: "100%" }}
                              min={1}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Weight (in Kg)"
                            name={[field.name, "weight"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please enter your Weight!",
                            //   },
                            // ]}
                          >
                            <InputNumber
                              disabled={isDisabled}
                              style={{ width: "100%" }}
                              min={1}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>

                    <Card
                      size="small"
                      key={field.key}
                      style={{ border: "none", backgroundColor: "#FAF5EB" }}
                    >
                      <Divider orientation="left" orientationMargin="0">
                        Pre Existing Disease - Yes/No, if Yes Specify Since when
                      </Divider>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Diabetes"
                            name={[field.name, "diabetes"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select an option!",
                            //   },
                            // ]}
                          >
                            <Select
                              disabled={isDisabled}
                              placeholder={SELECT_PLACEHOLDER}
                              options={[
                                {
                                  value: "Yes",
                                  label: "Yes",
                                },
                                {
                                  value: "No",
                                  label: "No",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldsValue }) =>
                              getFieldsValue()?.items &&
                              getFieldsValue()?.items[field?.name]?.diabetes ===
                                "Yes" ? (
                                <Form.Item
                                  name={[field.name, "diabetesDuration"]}
                                  label="Diabetes (Duration)"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please input Diabetes duration!",
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    disabled={isDisabled}
                                    style={{
                                      width: "100%",
                                    }}
                                    format={dateFormat}
                                    maxDate={dayjs(oneDayAgo, dateFormat)}
                                  />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Blood Pressure"
                            name={[field.name, "bloodpressure"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select an option!",
                            //   },
                            // ]}
                          >
                            <Select
                              disabled={isDisabled}
                              placeholder={SELECT_PLACEHOLDER}
                              options={[
                                {
                                  value: "Yes",
                                  label: "Yes",
                                },
                                {
                                  value: "No",
                                  label: "No",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldsValue }) =>
                              getFieldsValue()?.items &&
                              getFieldsValue()?.items[field.name]
                                ?.bloodpressure === "Yes" ? (
                                <Form.Item
                                  label="Blood Pressure (Duration)"
                                  name={[field.name, "bloodpressureDuration"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please input Blood Pressure duration!",
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    disabled={isDisabled}
                                    style={{
                                      width: "100%",
                                    }}
                                    format={dateFormat}
                                    maxDate={dayjs(oneDayAgo, dateFormat)}
                                  />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Asthma"
                            name={[field.name, "asthma"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select an option!",
                            //   },
                            // ]}
                          >
                            <Select
                              disabled={isDisabled}
                              placeholder={SELECT_PLACEHOLDER}
                              options={[
                                {
                                  value: "Yes",
                                  label: "Yes",
                                },
                                {
                                  value: "No",
                                  label: "No",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldsValue }) =>
                              getFieldsValue()?.items &&
                              getFieldsValue()?.items[field.name]?.asthma ===
                                "Yes" ? (
                                <Form.Item
                                  label="Asthma (Duration)"
                                  name={[field.name, "asthmaDuration"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Asthma duration!",
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    disabled={isDisabled}
                                    style={{
                                      width: "100%",
                                    }}
                                    format={dateFormat}
                                    maxDate={dayjs(oneDayAgo, dateFormat)}
                                  />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Cholesterol"
                            name={[field.name, "cholesterol"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select an option!",
                            //   },
                            // ]}
                          >
                            <Select
                              disabled={isDisabled}
                              placeholder={SELECT_PLACEHOLDER}
                              options={[
                                {
                                  value: "Yes",
                                  label: "Yes",
                                },
                                {
                                  value: "No",
                                  label: "No",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldsValue }) =>
                              getFieldsValue()?.items &&
                              getFieldsValue()?.items[field.name]
                                ?.cholesterol === "Yes" ? (
                                <Form.Item
                                  shouldUpdate
                                  label="Cholesterol (Duration)"
                                  name={[field.name, "cholesterolDuration"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please input Cholesterol duration!",
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    disabled={isDisabled}
                                    style={{
                                      width: "100%",
                                    }}
                                    format={dateFormat}
                                    maxDate={dayjs(oneDayAgo, dateFormat)}
                                  />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Thyroid (Hyper/Hypo)"
                            name={[field.name, "thyroid"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select an option!",
                            //   },
                            // ]}
                          >
                            <Select
                              disabled={isDisabled}
                              placeholder={SELECT_PLACEHOLDER}
                              options={[
                                {
                                  value: "Yes",
                                  label: "Yes",
                                },
                                {
                                  value: "No",
                                  label: "No",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldsValue }) =>
                              getFieldsValue()?.items &&
                              getFieldsValue()?.items[field.name]?.thyroid ===
                                "Yes" ? (
                                <Form.Item
                                  label="Thyroid (Duration)"
                                  name={[field.name, "thyroidDuration"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Thyroid duration!",
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    disabled={isDisabled}
                                    style={{
                                      width: "100%",
                                    }}
                                    format={dateFormat}
                                    maxDate={dayjs(oneDayAgo, dateFormat)}
                                  />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Genetic Disease"
                            name={[field.name, "genetic"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select an option!",
                            //   },
                            // ]}
                          >
                            <Select
                              disabled={isDisabled}
                              placeholder={SELECT_PLACEHOLDER}
                              options={[
                                {
                                  value: "Yes",
                                  label: "Yes",
                                },
                                {
                                  value: "No",
                                  label: "No",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldsValue }) =>
                              getFieldsValue()?.items &&
                              getFieldsValue()?.items[field.name]?.genetic ===
                                "Yes" ? (
                                <Form.Item
                                  label="Specify Genetic Disease"
                                  name={[field.name, "geneticDisease"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please input Genetic disease info!",
                                    },
                                    {
                                      pattern: /^[A-Za-z\s.-]*$/,
                                      message:
                                        "Please enter a valid Genetic disease info (no special characters)",
                                    },
                                  ]}
                                >
                                  <Input disabled={isDisabled} />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Any Surgery (if yes Specify Details and share with us)"
                            name={[field.name, "surgery"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select an option!",
                            //   },
                            // ]}
                          >
                            <Select
                              disabled={isDisabled}
                              placeholder={SELECT_PLACEHOLDER}
                              options={[
                                {
                                  value: "Yes",
                                  label: "Yes",
                                },
                                {
                                  value: "No",
                                  label: "No",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldsValue }) =>
                              getFieldsValue()?.items &&
                              getFieldsValue()?.items[field.name]?.surgery ===
                                "Yes" ? (
                                <Form.Item
                                  label="Surgery Details"
                                  name={[field.name, "surgeryName"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Surgery details",
                                    },
                                    {
                                      pattern: /^[A-Za-z0-9\s.,-]*$/,
                                      message:
                                        "Please enter a valid Surgery details (no special characters)",
                                    },
                                  ]}
                                >
                                  <TextArea disabled={isDisabled} />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Medication/Tablet (if yes Specify Details and share with us)"
                            name={[field.name, "medication"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select an option!",
                            //   },
                            // ]}
                          >
                            <Select
                              disabled={isDisabled}
                              placeholder={SELECT_PLACEHOLDER}
                              options={[
                                {
                                  value: "Yes",
                                  label: "Yes",
                                },
                                {
                                  value: "No",
                                  label: "No",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldsValue }) =>
                              getFieldsValue()?.items &&
                              getFieldsValue()?.items[field.name]
                                ?.medication === "Yes" ? (
                                <Form.Item
                                  label="Medication/Tablet Details"
                                  name={[field.name, "medicationName"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please input Medication Details!",
                                    },
                                    {
                                      pattern: /^[A-Za-z0-9\s.,-]*$/,
                                      message:
                                        "Please enter a valid Medication details (no special characters)",
                                    },
                                  ]}
                                >
                                  <TextArea disabled={isDisabled} />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>

                    <Card
                      size="small"
                      key={field.key}
                      style={{ border: "none", backgroundColor: "#FAF5EB" }}
                    >
                      <Divider orientation="left" orientationMargin="0">
                        Does the Person has the habbit of consuming below
                      </Divider>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Cigarette/Bidi"
                            name={[field.name, "cigarettebidi"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select Yes or No",
                            //   },
                            // ]}
                          >
                            <Select
                              disabled={isDisabled}
                              placeholder={SELECT_PLACEHOLDER}
                              options={[
                                {
                                  value: "Yes",
                                  label: "Yes",
                                },
                                {
                                  value: "No",
                                  label: "No",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldsValue }) =>
                              getFieldsValue()?.items &&
                              getFieldsValue()?.items[field.name]
                                ?.cigarettebidi === "Yes" ? (
                                <Form.Item
                                  label="Cigarette Per Day"
                                  name={[field.name, "cigarettebidicount"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Cigarette count!",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    disabled={isDisabled}
                                    style={{ width: "100%" }}
                                    max={100}
                                    min={1}
                                  />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Tabacco Pouches"
                            name={[field.name, "tabaccopouches"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select Yes or No",
                            //   },
                            // ]}
                          >
                            <Select
                              disabled={isDisabled}
                              placeholder={SELECT_PLACEHOLDER}
                              options={[
                                {
                                  value: "Yes",
                                  label: "Yes",
                                },
                                {
                                  value: "No",
                                  label: "No",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldsValue }) =>
                              getFieldsValue()?.items &&
                              getFieldsValue()?.items[field.name]
                                ?.tabaccopouches === "Yes" ? (
                                <Form.Item
                                  label="Pouches Per Day"
                                  name={[field.name, "tabaccopouchescount"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please input Tabacco Pouches count!",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    disabled={isDisabled}
                                    style={{ width: "100%" }}
                                    max={100}
                                    min={1}
                                  />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Alcohol"
                            name={[field.name, "alcohol"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select Yes or No",
                            //   },
                            // ]}
                          >
                            <Select
                              disabled={isDisabled}
                              placeholder={SELECT_PLACEHOLDER}
                              options={[
                                {
                                  value: "Yes",
                                  label: "Yes",
                                },
                                {
                                  value: "No",
                                  label: "No",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldsValue }) =>
                              getFieldsValue()?.items &&
                              getFieldsValue()?.items[field.name]?.alcohol ===
                                "Yes" ? (
                                <Form.Item
                                  label="Alcohol Frequency"
                                  name={[field.name, "alcoholcount"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please select frequency of Alcohol!",
                                    },
                                  ]}
                                >
                                  <Select
                                    disabled={isDisabled}
                                    placeholder={SELECT_PLACEHOLDER}
                                    options={[
                                      {
                                        value: "Daily",
                                        label: "Daily",
                                      },
                                      {
                                        value: "Weekly",
                                        label: "Weekly",
                                      },
                                      {
                                        value: "Monthly",
                                        label: "Monthly",
                                      },
                                      {
                                        value: "Occassionally",
                                        label: "Occasionally",
                                      },
                                    ]}
                                  />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Gutka Pouches"
                            name={[field.name, "gutkapouches"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select Yes or No",
                            //   },
                            // ]}
                          >
                            <Select
                              disabled={isDisabled}
                              placeholder={SELECT_PLACEHOLDER}
                              options={[
                                {
                                  value: "Yes",
                                  label: "Yes",
                                },
                                {
                                  value: "No",
                                  label: "No",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldsValue }) =>
                              getFieldsValue()?.items &&
                              getFieldsValue()?.items[field.name]
                                ?.gutkapouches === "Yes" ? (
                                <Form.Item
                                  label="Pouches Per Day"
                                  name={[field.name, "gutkapouchescount"]}
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please input Gutka pouches count!",
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    disabled={isDisabled}
                                    style={{ width: "100%" }}
                                    max={100}
                                    min={1}
                                  />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item
                            label="Drugs"
                            name={[field.name, "drugs"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please select Yes or No",
                            //   },
                            // ]}
                          >
                            <Select
                              disabled={isDisabled}
                              placeholder={SELECT_PLACEHOLDER}
                              options={[
                                {
                                  value: "Yes",
                                  label: "Yes",
                                },
                                {
                                  value: "No",
                                  label: "No",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row style={{ justifyContent: "space-between" }}>
                        <Col xs={24} sm={12} lg={10}>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldsValue }) =>
                              getFieldsValue()?.items &&
                              getFieldsValue()?.items[field.name]?.drugs ===
                                "Yes" ? (
                                <Form.Item
                                  label="Drugs Details"
                                  name={[field.name, "drugsdetails"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please specify Details!",
                                    },
                                  ]}
                                >
                                  <TextArea disabled={isDisabled} />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </Card>
                </>
              ))}

              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "5%",
                }}
              >
                <Space>
                  {!isDisabled && (
                    <>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Save
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => {
                            const insuredPersonList = [
                              ...form.getFieldsValue()?.items,
                              {},
                            ];
                            form.setFieldsValue({ items: insuredPersonList });
                            form.setFieldsValue({
                              NumberOfInsured: insuredPersonList?.length,
                            });
                          }}
                        >
                          Add insured Person
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Space>
              </Row>
            </div>
          )}
        </Form.List>
      </Form>

      <FloatButton.Group
        trigger="hover"
        type="primary"
        style={{
          right: 20,
        }}
        tooltip={<div>More Options</div>}
        icon={<SettingOutlined />}
      >
        <FloatButton
          type="primary"
          icon={<ShareAltOutlined />}
          tooltip={<div>Share Health Declaration</div>}
          onClick={() => {
            setIsShareModalOpen(true);
            setShareUrl(
              `https://${window.location.host}/new-health-declaration?declarationToken=${activeQuote?.declarationToken}&quoteId=${activeQuote?.id}`
            );
          }}
        />

        <FloatButton
          type="primary"
          icon={<FormOutlined />}
          tooltip={<div>Edit Health Declaration</div>}
          onClick={() => {
            setIsDisabled(false);
          }}
        />
      </FloatButton.Group>
    </Row>
  );
};

export default AgentViewHealthDeclarationForm;
