import {
  Button,
  Card,
  Divider,
  Result,
  Select,
  Space,
  Tabs,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveAccount } from "../store/reducers/authReducer";
import { fetchCustomerAccountsList } from "../store/reducers/customerReducer";
import AddAccountForm from "./AddAccountForm";
import CustomerDocsTable from "./CustomerDocsTable";
import ExistingPolicyTable from "./ExistingPolicyTable";
import FamilyMemberTable from "./FamilyMemberTable";
import LoadingSpinner from "./Loader";

const { Text } = Typography;

// Component for displaying Customer Accounts table
const CustomerAccountsTable = ({ customer }) => {
  const dispatch = useDispatch();
  const activeCustomerAccounts = useSelector(
    (state) => state?.customer?.customerAccounts
  );
  const activeCustomer = useSelector((state) => state?.auth?.activeCustomer);
  const [uploading, setUploading] = useState(false);
  const agentAuthToken = useSelector((state) => state?.auth?.token);
  const [isAccountFormVisisble, setAccountFormVisible] = useState(false);
  const [selectedAccountInfo, setSelectedAccountInfo] = useState(
    {

      label: activeCustomerAccounts[0]?.name,
      value: activeCustomerAccounts[0]?.id,
    }
  );

  useEffect(() => {

    // set default account 
    // debugger
    if(activeCustomerAccounts?.length > 0) {
      let defActiveAccount = activeCustomerAccounts[0];

      setSelectedAccountInfo({
        accountId: defActiveAccount.id,
        accountName: defActiveAccount?.name,
      });

      dispatch(
        setActiveAccount(
          activeCustomerAccounts?.find(
            (customerAcc) => customerAcc?.id === defActiveAccount.id
          )
        )
      );

    }
    
  }, [activeCustomer, activeCustomerAccounts, dispatch])

  const tabList = ["Policies", "Docs", "Members"];
  const operations = (
    <>
      <Text style={{ fontWeight: "bold", fontSize: "16px" }}>
        {selectedAccountInfo?.accountId
          ? "Selected Account  "
          : "Select Account  "}
        {"  "}
      </Text>
      <Space>
        <Select
          style={{
            width: 300,
          }}
          defaultActiveFirstOption={true}
          defaultValue={selectedAccountInfo}
          placeholder="Select Account"
          onChange={(value, option) => {
            setSelectedAccountInfo({
              accountId: value,
              accountName: option?.label,
            });
            dispatch(
              setActiveAccount(
                activeCustomerAccounts?.find(
                  (customerAcc) => customerAcc?.id === value
                )
              )
            );
          }}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider
                style={{
                  margin: "8px 0",
                }}
              />
              <Space
                style={{
                  padding: "0 8px 4px",
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    setAccountFormVisible(true);
                  }}
                >
                  New Account
                </Button>
              </Space>
            </>
          )}
          options={activeCustomerAccounts?.map((item) => ({
            label: item?.name,
            value: item?.id,
          }))}
        />
      </Space>
    </>
  );

  useEffect(() => {
    setUploading(true);
    const data = { customerId: activeCustomer?.id, token: agentAuthToken };
    dispatch(fetchCustomerAccountsList(data)).then((response) => {
      if (response?.payload?.data?.success === true) {
        setUploading(false);
      } else {
        setUploading(false);
      }
    });
  }, [agentAuthToken, activeCustomer, dispatch]);

  return (
    <Card>
      <LoadingSpinner loading={uploading} />
      <AddAccountForm
        visible={isAccountFormVisisble}
        setVisible={setAccountFormVisible}
        customer={activeCustomer}
      />

      <Tabs
        style={{ marginTop: "5px" }}
        type="card"
        tabBarExtraContent={operations}
        items={tabList?.map((tabHeader) => {
          return {
            label: tabHeader,
            key: tabHeader,
            children: selectedAccountInfo?.accountId ? (
              tabHeader === "Policies" ? (
                <ExistingPolicyTable customer={activeCustomer} />
              ) : tabHeader === "Members" ? (
                <FamilyMemberTable customer={activeCustomer} />
              ) : (
                <CustomerDocsTable customer={activeCustomer} />
              )
            ) : (
              <Result
                status="404"
                title="Account Not Selected!"
                subTitle="Please select an Account to view the details"
              />
            ),
          };
        })}
      />
    </Card>
  );
};

export default CustomerAccountsTable;
