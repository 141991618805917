import React, { useState } from "react";
import { Button, Result, Layout, Flex } from "antd";

import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import MenuBar from "./components/MenuBar";
import LoginPage from "./pages/LoginPage";
import Dashboard from "./pages/Dashboard";

import {
  Navigate,
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import CustomerAccount from "./pages/CustomerAccount";
import CustomerPage from "./pages/claim/CustomerPage";
import TeamMembers from "./pages/TeamMembers";
import NewHealthDeclarationForm from "./pages/NewHealthDeclartionForm";
import InsuranceProducts from "./pages/InsuranceProducts";
import ViewQuotePDF from "./pages/ViewQuotePDF";
import NewQuote from "./pages/NewQuote";
import QuoteComparison from "./components/QuoteComparison";

const { Content, Sider } = Layout;

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={() => navigate("/dashboard")}>
          Back Dashboard
        </Button>
      }
    />
  );
};

const App = () => {
  const isAuthenticated = useSelector((state) => state.auth.is_authenticated);
  const isApproved = useSelector((state) => state.auth.is_approved);
  const [collapsed, setCollapsed] = useState(true);
  const toggleCollapsed = () => {
    setCollapsed(false);
  };
  const toggleCloseCollapsed = () => {
    setCollapsed(true);
  };

  return (
    <Flex wrap="wrap">
      <Layout>
        <AppHeader />
        <Content>
          <Layout style={{}}>
            <BrowserRouter>
              {isAuthenticated && isApproved ? (
                <Sider
                  // trigger={null}
                  // collapsible
                  // collapsed={collapsed}
                  // onMouseEnter={toggleCollapsed}
                  // onMouseLeave={toggleCloseCollapsed}
                  position="fixed"
                  style={{
                    paddingTop: "1%",

                    // height: "90vh",
                  }}
                >
                  <MenuBar />
                </Sider>
              ) : null}

              <Routes>
                {isAuthenticated && isApproved ? (
                  <>
                    <Route path="/team-members" element={<TeamMembers />} />
                    <Route
                      path="/quote-proposal"
                      element={<QuoteComparison />}
                    />
                    <Route
                      path="/customer-account/new-quote"
                      element={<NewQuote />}
                    />
                    <Route
                      path="/customer-account"
                      element={<CustomerAccount />}
                    />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/" element={<Dashboard />} />

                    <Route path="/claims" element={<CustomerPage />} />
                    <Route path="/not-found" element={<NotFoundPage />} />
                    <Route path="*" element={<Navigate to="/not-found" />} />
                    <Route
                      path="/new-health-declaration"
                      element={<NewHealthDeclarationForm />}
                    />
                    <Route
                      path="/insurance-products"
                      element={<InsuranceProducts />}
                    />
                  </>
                ) : (
                  <>
                    <Route path="/claims" element={<CustomerPage />} />
                    <Route path="/" element={<LoginPage />} />
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route
                      path="/new-health-declaration"
                      element={<NewHealthDeclarationForm />}
                    />
                    <Route path="/proposed-quote" element={<ViewQuotePDF />} />
                  </>
                )}
              </Routes>
            </BrowserRouter>
          </Layout>
        </Content>
        <AppFooter />
      </Layout>
    </Flex>
  );
};

export default App;
