import React, { useState } from "react";
import { Button, Modal } from "antd";
import { EyeTwoTone } from "@ant-design/icons";
import API from "../store/utils/apiEndPoint";

const ViewFile = ({ documentFilename, folderPath }) => {
  const [url, setURL] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  async function viewDocument(filename) {
    const response = await API.get(
      `/get-presigned-url?filename=${filename}&folderPath=${folderPath}`
    );
    setURL(response?.data?.url);
    showModal();
  }

  return (
    <>
      <Modal
        title="View Document"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="80%"
        style={{ top: 5 }}
      >
        <div style={{ height: "80vh" }}>
          <iframe title="PDF Viewer" src={url} width="100%" height="100%" />
        </div>
      </Modal>

      <Button onClick={() => viewDocument(documentFilename)}>
        <EyeTwoTone />
      </Button>
    </>
  );
};

export default ViewFile;
