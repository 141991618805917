import { Button, Flex, Modal, Table, Typography, Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDocsList,
  deleteDocumentData,
} from "../store/reducers/customerReducer";
import AddCustomerDocsForm from "./AddCustomerDocsForm";
import LoadingSpinner from "./Loader";
import { DeleteTwoTone, ExclamationCircleFilled } from "@ant-design/icons";
import ViewFile from "./ViewFile";
import dayjs from "dayjs";
const { confirm } = Modal;

const { Text } = Typography;

// Component for displaying Customer Docs table
const CustomerDocsTable = ({ customer }) => {
  const [isAddDocFormVisible, setAddDocFormVisible] = useState(false);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const documentList = useSelector((state) => state?.customer?.documentList);
  const activeAccount = useSelector((state) => state?.auth?.activeAccount);
  const agentAuthToken = useSelector((state) => state?.auth?.token);

  const showDeleteConfirm = (record) => {
    confirm({
      title: "Are you sure delete this Document?",
      icon: <ExclamationCircleFilled />,
      content: "Deleted documents cannot be restored.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setUploading(true);
        const data = { ...record, customerId: customer?.customerId };
        dispatch(deleteDocumentData(data)).then((response) => {
          if (response?.payload?.success === true) {
            dispatch(fetchDocsList(data)).then((result) => {
              if (result?.payload?.success === true) {
                setUploading(false);
                message.success("Document Deleted Successfully");
              } else {
                setUploading(false);
                message.success("Document Deleted Successfully");
              }
            });
          } else {
            setUploading(false);
            message.error("Something went wrong, please try again later");
          }
        });
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: "Document Type",
      dataIndex: "documentType",
      key: "documentType",
      render: (_, record) => <>{record?.type}</>,
    },
    {
      title: "Document Name",
      dataIndex: "documentName",
      key: "documentName",
      render: (_, record) => <>{record?.name}</>,
    },
    {
      title: "Uploaded Date",
      dataIndex: "uploadedDate",
      key: "uploadedDate",
      render: (_, record) => {
        return (
          <>
            {record?.uploadedDate
              ? dayjs(record?.uploadedDate).format("DD-MMMM-YYYY")
              : "Not Available"}
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "documentUrl",
      key: "documentUrl",
      render: (_, record) => (
        <Flex gap={16}>
          <Tooltip title={"View Document"}>
            <ViewFile
              documentFilename={record?.documentFilename}
              folderPath={`${customer?.firstName}_${customer?.lastName}/${activeAccount?.name}/documents`}
            />
          </Tooltip>
          {/* <Tooltip title={"Delete Document"}>
            <Button onClick={() => showDeleteConfirm(record)}>
              <DeleteTwoTone twoToneColor="#dc3545" />
            </Button>
          </Tooltip> */}
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    setUploading(true);
    const data = { accountId: activeAccount?.id, token: agentAuthToken };
    dispatch(fetchDocsList(data)).then((response) => {
      if (response?.payload?.data?.success === true) {
        setUploading(false);
      } else {
        setUploading(false);
      }
    });
  }, [customer, dispatch, activeAccount]);

  return (
    <>
      <AddCustomerDocsForm
        visible={isAddDocFormVisible}
        setVisible={setAddDocFormVisible}
        customer={customer}
      />

      <LoadingSpinner loading={uploading} />

      <Table
        columns={columns}
        dataSource={documentList}
        pagination={false}
        scroll={{
          y: 170,
        }}
        title={() => {
          return (
            <Flex dir="row" justify={"space-between"}>
              <Text style={{ fontWeight: 600, fontSize: 16 }}>Documents</Text>
              <Button
                type="primary"
                onClick={() => {
                  setAddDocFormVisible(true);
                }}
              >
                Add Document
              </Button>
            </Flex>
          );
        }}
      />
    </>
  );
};

export default CustomerDocsTable;
